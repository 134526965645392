import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { getUserInfo } from "../../utils/auth";

const Profile = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    }
  }));
  const classes = useStyles();
  const [profile, setProfile] = useState("");
  useEffect(() => {
    const getProfile = typeof window !== "undefined" ? getUserInfo() : "";
    console.log(getProfile);
    setProfile(getProfile);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper>
        <div className={classes.root}>
          <Grid
            container
            className={classes.grid}
            justify="center"
            directin="row"
            alignItems="center"
          >
            <Avatar src={profile.picture} className={classes.large} />
          </Grid>
          <Grid
            container
            className={classes.grid}
            justify="center"
            directin="row"
            alignItems="center"
          >
            <Typography variant="h5" value>
              {profile.name}
            </Typography>
          </Grid>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default Profile;
