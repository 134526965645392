import React from "react";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Profile from "../components/Profile";

const ProfilePage = props => {
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <Profile theme={theme} />
          </Article>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

export default ProfilePage;
